const path = require(`path`);

export const locales = [
  'en-US',
  'de-DE',
  'ja-JP',
  'fr-FR',
  'en-GB',
  'en-CA',
  'en-AU',
  'en-IN',
  'es-ES',
  'it-IT',
  'ko-KR',
  'pt-BR',
  'zh-CN',
  'zh-TW',
  'es-LA',
  'nl-NL',
  'id-ID',
  'th-TH',
  'ru-RU',
  'sv-SE',
  'vi-VN',
] as const;

export type Locale = typeof locales[number];

export function getLocaleSubset<LocaleSubset extends Locale[]>(subset: LocaleSubset): LocaleSubset {
  return subset;
}

export const euLocales = getLocaleSubset([
  'nl-NL',
  'en-GB',
  'fr-FR',
  'de-DE',
  'it-IT',
  'pt-BR',
  'es-ES',
  'es-LA',
  'sv-SE',
]);

export const asianLocales = getLocaleSubset(['ja-JP', 'ko-KR', 'zh-CN', 'zh-TW']);

export type EULocale = typeof euLocales[number];
export type AsianLocale = typeof asianLocales[number];

export function isEULocale(locale: Locale): locale is EULocale {
  return (euLocales as Locale[]).includes(locale);
}

export function isAsianLocale(locale: Locale): locale is AsianLocale {
  return (asianLocales as Locale[]).includes(locale);
}

export const shortLocaleMap = locales.reduce((prev, curr) => {
  return {
    ...prev,
    [curr.split('-').join('')]: curr,
  };
}, {}) as { [key: string]: Locale };

// English-only in development
if (process.env.ENGLISH_ONLY === 'true') {
  (locales as any).length = 2;
}

export const LocaleMissing = 'LocaleMissing';
export type LocaleMissing = typeof LocaleMissing;

export const defaultLocale: Locale = 'en-US';

export function isValidLocale(maybeLocale: any): maybeLocale is Locale {
  return locales.includes(maybeLocale);
}

function getByTag(maybeLocale: string): Locale {
  const localeIndex = locales.map(locale => locale.toLowerCase()).indexOf(maybeLocale);

  if (localeIndex === -1) {
    return defaultLocale;
  }

  return locales[localeIndex];
}

export function getLocaleFromPath(path: string): Locale {
  const [maybeLocale] = path.split('/').filter(component => !!component);

  if (typeof maybeLocale !== 'string') return defaultLocale;

  return getByTag(maybeLocale);
}

export function stripLocale(url: string) {
  const exists = locales.find(l => url.indexOf(l.toLocaleLowerCase()) >= 0);
  if (exists) {
    return path.resolve(url.replace(exists.toLocaleLowerCase(), ''));
  }
  return url;
}

export function getResolvedLocalePath(locale: Locale, pagePath: string) {
  if (locale === defaultLocale) {
    return path.resolve(`/${pagePath}/`);
  }
  return path.resolve(`/${locale.toLocaleLowerCase()}/${pagePath}/`);
}

export function getLocalesAsString(joinBy: string) {
  return locales.map(locale => locale.toLowerCase()).join(joinBy);
}
