import { EUCountry } from './common/util/trace';
import { ConsentGroups } from './common/scripts/onetrust';

export interface Redwood {
  consentGroups: ConsentGroups;
  country: string | EUCountry;
  colo: string;
  rv1?: any;
  rv2?: any;
}

export interface MaybeRedwood {
  redwood?: Redwood;
}

export const config = {
  backendEndpoint: '/marketing-api',
  bizible_enabled: true,
  cache_asset_maps: true,
  contentful_space_id: 'slt3lc6tev37',
  cookie_domain: 'cloudflare.com',
  google_adwords_conversion_code_form: 'AW-836330296',
  google_adwords_conversion_code: 'AW-972248548',
  google_adwords_conversion_label_form: '-l5JCJnczYgBELjG5Y4D',
  google_adwords_conversion_label: 'caqvCO3e32EQ5KvNzwM',
  google_adwords_ua_code_bi: 'DC-8921596',
  google_adwords_ua_code: 'AW-819572718',
  google_analytics_cookie_domain: '.cloudflare.com',
  google_analytics_ua_code_www_marketing_29: 'UA-10218544-29',
  google_optimize: 'GTM-N4JSZJ8',
  google_tag_manager_container: 'GTM-PKQFGQB',
  host: 'https://www.cloudflare.com',
  marketoDomain: 'https://info.cloudflare.com',
  ignore_release_at: false,
  log_level: 'combined',
  marketo_enabled: true,
  marketo_form_js_id: 'ab13',
  marketo_form_munchkin: '713-XSC-918',
  marketo_form_sandbox_js_id: 'ab19',
  marketo_form_sandbox_munchkin: '100-ATQ-487',
  mongodb_database: 'contentful',
  remarketing_enabled: false,
  rum_enabled: true,
  sales_number_lp_pages: '+1 650 319 8930',
  sentry_dsn_backend: 'https://7c04afc7d70747599bfd11d12fb26177@sentry.io/229513',
  sentry_dsn_frontend: 'https://bd9df81d859641f39ce626ce2a2cc90d@sentry.io/229513',
  sentry_enabled: true,
  sparrow_enabled: true,
  ent_email_enabled: true,
  techtarget_enabled: true,
};
